import React from 'react';
import { graphql } from 'gatsby';
import { LanguageLayout } from '../containers/layouts';
import ArticleHeader from './../containers/articleHeader';
import Wrapper from './../components/wrapper/';
import WrapperContent from './../components/wrapperContent/';
import Content from './../components/content/';

export const PlainPageTemplate = ({ frontmatter, html }) => (
    <article>
        <ArticleHeader title={frontmatter.title} />
        <Wrapper>
            <WrapperContent>
                <Content html={html} />
            </WrapperContent>
        </Wrapper>
    </article>
);

const PlainPage = (props) => (
    <LanguageLayout location={props.location}>
        <PlainPageTemplate
            frontmatter={props.data.allMarkdownRemark.edges[0].node.frontmatter}
            html={props.data.allMarkdownRemark.edges[0].node.html}
        />
    </LanguageLayout>
);

export default PlainPage;

export const pageQuery = graphql`
query PlainPageTemplate($slug: String!) {
    allMarkdownRemark(
        filter: {
            frontmatter: {
                templateKey: { eq: "plain-page"}
            },
            fields: {
                slug: {eq: $slug}
            }
        }
    )
    {
        edges {
            node {
                id
                frontmatter {
                    title
                }
                html
            }
        }
    }
}
`;
